import Button from "../button/button"
import Carousel, { IItem } from "../carousel/carousel"
import { IProduct } from "../../App"
import { useParams } from "react-router"

const renderItems = (products: IProduct[], id: string) => {
    let items: IItem[] = []

    const product = products.filter(p => p.id === id)[0]
    if (!product) return []

    product.drinks.forEach(d => items.push({ thumbnail: d.image, bottle: product.image, title: d.name, text: d.shortdescription, id: product.id, slug: d.slug }))
    return items;
}

const Drink: React.FC<{ products: IProduct[] }> = ({ products }) => {
    const { id } = useParams<{ id: string }>()
    return (
        <div className="px-3 pb-4">
            <Carousel items={renderItems(products, id)} />
            <div className="mt-4">
                <Button link="/konkurs" text="Weź udział w konkursie" stripes="stripes-light" novelty />
                <Button link="/przepisy" text="Odkryj wszystkie przepisy" stripes="stripes-dark" />
            </div>
        </div>
    )
}

export default Drink