import {
  FC,
  // useState
} from "react";

// import FileInput from "../../components/file_input/file_input";
// import Checkbox from "../../components/checkbox/checkbox";

import woman_drink from "../../assets/img/konkurs_img.png";
import trophy from "../../assets/img/trophy_circles.png";
import awards from "../../assets/img/awards_new.png";
import awards_bubbles from "../../assets/img/awards_bubbles.png";
import num1 from "../../assets/img/num1.png";
import num2 from "../../assets/img/num2.png";
import num3 from "../../assets/img/num3.png";
import drink_vector from "../../assets/img/drink_vector.png";
import drink2_vector from "../../assets/img/drink2_vector.png";
import photo_vector from "../../assets/img/photo_vector.png";
// import styled from "styled-components";

const Konkurs: FC = () => {
  //   const [loading, setLoading] = useState(false);
  //   const [email, setEmail] = useState("");
  //   const [emailError, setEmailError] = useState("");
  //   const [photo, setPhoto] = useState("");
  //   const [photoError, setPhotoError] = useState("");
  //   const [agreement, setAgreement] = useState(false);
  //   const [agreementError, setAgreementError] = useState("");

  //   const [submitted, setSubmitted] = useState(false);
  //   const [submitError, setSubmitError] = useState(false);

  return (
    <div>
      <div className="w-full mt-6">
        <img src={trophy} className="w-full" alt="" />
        <div className="text-4xl font-bold text-center uppercase festivo">Konkurs</div>
      </div>
      <div className="px-4 mt-4">
        <div className="rounded-3xl">
          <div className="relative overflow-hidden rounded-8">
            <img src={woman_drink} alt="woman" />
            <div className="absolute bottom-0 left-0 z-10 flex items-end justify-center w-full px-5 text-4xl font-semibold text-center text-white uppercase h-1/2 bg-gradient-to-t from-black to-gransparent festivo">
              MIXUJ PO SWOJEMU <br /> I WYGRAJ
            </div>
          </div>
          <div className="px-5 pt-3 pb-3 font-medium text-white bg-black rounded-b-3xl">
            Zainspiruj się przepisami na drinki ze Schweppes i namieszaj po swojemu w konkursie!
          </div>
        </div>
      </div>
      <div className="px-4 mt-12">
        <p className="text-2xl font-semibold text-center uppercase festivo">Nagrody</p>
        <div className="relative w-full ">
          <img src={awards} alt="" className="w-full" />
        </div>
        <div className="relative mt-3">
          <p className="text-lg font-bold text-center">1x Głośnik JBL Boombox</p>
          <p className="text-lg font-bold text-center">5x Głośnik JBL Go</p>
          <p className="text-lg font-bold text-center">10x zestaw Schweppes</p>
          <img src={awards_bubbles} className="absolute top-0 left-0 z-0" alt="bubbles" />
        </div>
      </div>

      <div className="px-4 my-10">
        <p className="text-2xl font-semibold text-center uppercase festivo">Zasady</p>
        <div className="mt-7">
          <div className="relative w-24 mx-auto">
            <img src={drink_vector} alt="" className="" />
            <img src={num1} alt="" className="absolute w-16 transform -translate-y-1/2 -left-28 top-1/2" />
          </div>
          <p className="mt-4 text-lg font-semibold text-center">
            Zmiksuj autorskiego drinka na bazie dowolnego Schweppes’a i ulubionych dodatków.
          </p>
        </div>
        <div className="mt-7">
          <div className="relative w-24 mx-auto">
            <img src={drink2_vector} alt="" className="" />
            <img src={num2} alt="" className="absolute w-16 transform -translate-y-1/2 -left-28 top-1/2" />
          </div>
          <p className="mt-4 text-lg font-semibold text-center">
            Możesz go zrobić: w ekstrawaganckim szkle, z oryginalnym przystrojeniem, na ciekawym tle. Byle po swojemu!
          </p>
        </div>
        <div className="mt-7">
          <div className="relative w-32 mx-auto">
            <img src={photo_vector} alt="" className="" />
            <img src={num3} alt="" className="absolute w-16 transform -translate-y-1/2 -left-24 top-1/2" />
          </div>
          <p className="mt-4 text-lg font-semibold text-center">Zrób fotkę drinka i prześlij!</p>
        </div>
        <div className="mt-14">
          <a href="/regulamin.pdf" className="cursor-pointer" target={"_blank"}>
            <div className="flex items-center justify-center mb-14">
              <label
                className="w-7 h-7 rounded-full bg-dark_yellow p-1.5 block flex-shrink-0 mr-2 cursor-pointer"
                htmlFor="chck"
              >
                <div className="w-full h-full bg-black rounded-full"></div>
              </label>
              <div className="text-xl">Regulamin Konkursu</div>
            </div>
          </a>
          <p className="mt-4 text-2xl font-semibold text-center uppercase">Konkurs zakończył się</p>
        </div>
      </div>

      {/* {!submitted && (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            let hasErrors = false;
            setEmailError("");
            setPhotoError("");
            setAgreementError("");
            const regex =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!email) {
              setEmailError("Wpisz adres email");
              hasErrors = true;
            } else if (!regex.test(email)) {
              setEmailError("Niepoprawny adres email");
              hasErrors = true;
            }
            if (!agreement) {
              setAgreementError("Zaznacz zgodę");
              hasErrors = true;
            }
            if (!photo) {
              setPhotoError("Dodaj zdjęcie konkursowe");
              hasErrors = true;
            }
            if (hasErrors) {
              setLoading(false);
              return;
            }
            try {
              const payload = {
                email: email,
                image: photo,
                term1: agreement,
                term2: 1,
                term3: 0,
                edition: 1,
              };
              const data = await fetch("https://api.schweppespolska.pl/save", {
                method: "POST",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              });
              const json = await data.json();
              console.log(json);
              if (json.code === 200) {
                setSubmitted(true);
              } else setSubmitError(true);
            } catch (error: any) {
              const err = { ...error };
              console.log(err);
            }
            setLoading(false);
          }}
          className="w-full px-4"
        >
          <p className="mt-10 mb-5 text-2xl font-semibold text-center uppercase festivo">Wez udzial</p>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-5 py-4 mb-3 text-lg font-semibold placeholder-black bg-white rounded-2xl focus:outline-none"
            placeholder="Adres e-mail"
          />
          {emailError && <span className="text-sm font-bold text-red-600">{emailError}</span>}
          <FileInput setImage={setPhoto} />
          {photoError && <span className="text-sm font-bold text-red-600">{photoError}</span>}
          <div className="mt-5">
            <Checkbox checked={agreement} setChecked={setAgreement} />
            {agreementError && <span className="text-sm font-bold text-red-600">{agreementError}</span>}
          </div>
          <div className="mt-5">
            <SubmitButton
              type="submit"
              className="block w-full pt-5 pb-5 mt-2 text-3xl font-bold text-center rounded-2xl stripes-dark festivo"
              disabled={loading}
            >
              Wyslij zgłoszenie
            </SubmitButton>
          </div>
          {submitError && <span className="text-sm font-bold text-red-600">Wystąpił błąd spróbuj ponownie</span>}
        </form>
      )}
      {submitted && (
        <div className="px-6 text-4xl font-bold text-center uppercase festivo">Twoje zgłoszenie zostało zapisane!</div>
      )}
      <p className="mt-4 text-lg font-semibold text-center">Konkurs trwa do 24.11.2022 r.</p> */}
    </div>
  );
};

export default Konkurs;

// const SubmitButton = styled.button`
//   transition: all 0.2s;

//   &:disabled {
//     filter: grayscale(0.8);
//   }
// `;
