import { FC, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

interface IProps {
    navOpen: boolean
    toggleNav: (arg: boolean) => void,
}

const Sidenav: FC<IProps> = ({ navOpen, toggleNav }) => {
    const location = useLocation()
    useEffect(() => {
        toggleNav(false)
    }, [location.pathname])
    return (
        <div className={`w-72 h-screen bg-black flex-shrink-0 fixed right-0 top-0 transform px-4  py-5 duration-200 ${navOpen ? 'translate-x-0' : 'translate-x-72'}`}>
            <div className="">
                <div className={`w-10 h-6 flex flex-col justify-between ml-auto cursor-pointer`} onClick={() => toggleNav(!navOpen)}>
                    <span className='block w-full h-1 bg-white  rounded-full'></span>
                    <span className='block w-full h-1 bg-white  rounded-full'></span>
                    <span className='block w-full h-1 bg-white  rounded-full'></span>
                </div>
            </div>
            <div>
                <ul className="text-white uppercase font-medium text-44">
                    <li className="my-6">
                        <Link to="/homepage" className="festivo">
                            Start
                        </Link>
                    </li>
                    <li className="my-6">
                        <Link to="/przepisy" className="festivo">
                            Przepisy
                        </Link>
                    </li>
                    <li className="my-6">
                        <Link to="/konkurs" className="festivo">
                            Konkurs
                        </Link>
                    </li>
                    <li className="my-6">
                        <Link to="/smaki" className="festivo">
                            Smaki
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidenav