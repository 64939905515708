import { FC } from "react";

import Button from "../../components/button/button";

import drinks from "../../assets/img/drink_circles.png";
import trophy from "../../assets/img/trophy_circles.png";
import bottle from "../../assets/img/bottle_circles.png";
import btn_end from "../../assets/img/btn_end.png";
import logo from "../../assets/img/logo.png";
import circle_black from "../../assets/img/circle_black.png";
import { Link } from "react-router-dom";

const Homepage: FC = () => {
  return (
    <div>
      <div className="relative mb-8 -mt-16">
        <div className="relative z-40 mx-auto w-44">
          <img src={logo} alt="schweppes" className="w-full" />
        </div>

        <img src={circle_black} alt="" className="absolute w-8 right-4 bottom-5" />
        <div className="absolute w-8 h-8 border border-black rounded-full top-4 left-8"></div>
      </div>
      <div>
        <div className="relative w-full">
          <p className="block -ml-8 font-bold text-center uppercase text-7xl festivo">Miksuj</p>
          <p className="block font-bold text-center uppercase text-7xl festivo">Jak</p>
          <p className="block -mr-5 font-bold text-center uppercase text-7xl festivo">
            <span className="text-stripes festivo">lubisz</span>
          </p>

          <div className="absolute w-2 h-2 bg-black rounded-full left-8 top-8"></div>
          <div className="absolute w-3 h-3 bg-black rounded-full right-5 bottom-3"></div>
          <div className="absolute w-4 h-4 border border-black rounded-full right-3 bottom-1"></div>
        </div>
        <div className="w-full mt-12">
          <img src={drinks} alt="drink_circles" />
          <div className="px-4 mt-4 font-medium leading-snug">
            Chcesz rozkręcić kubki smakowe swoich gości? Zainspiruj się przepisami na drinki ze Schweppes i zmixuj coś
            niesamowitego.
            <div className="mt-5">
              <Button link="/przepisy" stripes="stripes-dark" text="Odkryj przepisy" />
            </div>
          </div>
        </div>
        <div className="w-full mt-16">
          <img src={trophy} alt="drink_circles" />
          <div className="px-4 mt-4 font-medium leading-snug">
            Masz ochotę nieźle namieszać, a przy okazji zawalczyć o ciekawe nagrody? Koniecznie sprawdź nasze konkursy!
            <div className="mt-5">
              <Link to="/konkurs">
                <img src={btn_end} alt="btn end" className="w-full" />
              </Link>
              {/* <Button link="/konkurs" stripes="stripes-light" text="KONKURS ZAKONCZYL SIE"  /> */}
            </div>
          </div>
        </div>
        <div className="w-full mt-16 mb-10">
          <img src={bottle} alt="drink_circles" />
          <div className="px-4 mt-4 font-medium leading-snug">
            Poznaj całą gamę wyjątkowych smaków Schweppes. Porównuj je ze sobą, eksperymentuj, mieszaj z ulubionymi
            dodatkami i… smakuj życie po swojemu.
            <div className="mt-5">
              <Button link="/smaki" stripes="stripes-dark" text="Odkryj smaki" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
