import { FC } from "react"
import { BsEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs'
import { useLocation } from "react-router"

import circle_border from '../../assets/img/circle_border.png'
import white_circle from '../../assets/img/white_circle.png'

const Footer: FC = () => {
    const location = useLocation()
    return (
        <>
            {
                location.pathname !== '/' ? <div className="text-center w-full py-10 relative overflow-hidden">
                    <p className="font-semibold">Orangina Schweppes Polska sp. z.o.o.</p>
                    <p>Aleja Wyścigowa 6, 02-681</p>
                    <p>Warszawa, Polska</p>
                    <div className="flex justify-center mt-3">
                        <a href="tel:+48608598423" className="flex items-center font-normal">
                            <div className="w-7 h-7 rounded-full flex items-center justify-center bg-black text-yellow mr-2">
                                <BsFillTelephoneFill />
                            </div>
                            <span>
                                +48 22 4316 800
                            </span>
                        </a>
                    </div>
                    <div className="hidden justify-center mt-1 d-none">
                        <a href="mailto:konkurs-schweppes@h2ocreative.pl" className="flex items-center font-normal">
                            <div className="w-7 h-7 rounded-full flex items-center justify-center bg-black text-yellow mr-2">
                                <BsEnvelopeFill />
                            </div>
                            <span>
                                konkurs-schweppes@h2ocreative.pl
                            </span>
                        </a>
                    </div>
                    <div className="flex justify-center items-center text-sm mt-3 font-normal">
                        <a href="https://www.schweppes.pl/privacy-policy" target={'_blank'}>Polityka prywatności</a>
                        <div className="mx-2">
                            &#9679;
                        </div>
                        <a href="https://www.schweppes.pl/cookie-policy" target={'_blank'}>Pliki cookies</a>
                    </div>

                    <img src={circle_border} className="absolute -right-8 top-1/3 w-16" alt="" />
                    <img src={white_circle} className="absolute -left-3 top-1/2 w-8" alt="" />

                    <div className="w-4 h-4 rounded-full bg-black absolute left-6 top-20"></div>
                    <div className="w-4 h-4 rounded-full bg-black absolute right-6 bottom-0"></div>
                    <div className="w-4 h-4 rounded-full bg-black absolute right-5 top-5"></div>

                    <div className="w-6 h-6 rounded-full border border-black absolute right-2 top-6"></div>
                </div> : null
            }
        </>
    )
}

export default Footer