import styled from "styled-components"

interface IProps {
    title: string,
    section: string,
    text: string
}

const DetailCarouselItem: React.FC<IProps> = ({ title, section, text }) => {
    return (
        <div className="bg-black text-white text-left px-10 pt-6 pb-16 min-h-full">
            <h2 className="text-4xl font-bold festivo">{title}</h2>
            <P className="font-semibold mt-5 text-lg">
                {section}
            </P>
            <SlideContent className="font-light" dangerouslySetInnerHTML={{ __html: text }}>

            </SlideContent>
        </div>
    )
}

export default DetailCarouselItem

const P = styled.div`
    font-family: 'EB Garamond', serif;
`
const SlideContent = styled.div`
    & ul{
        list-style-type: none;
        padding-left: 1rem;

        & li{
            margin:  4px 0;
            position: relative;

            &::before{
                content: "";
                background: #fadb0f;
                font-weight: bold;
                display: inline-block; 
                width: 5px;
                height: 5px;
                border-radius: 100rem;
                position: absolute;
                left: -1rem;
                top: 50%;
                transform:translateY(-50%) ;
            }
        }
    }
`