import Item from "./item/item"
import { IProduct } from "../../App"
import { useState } from "react"
import drinks from '../../assets/img/drink_circles.png'

const Menu: React.FC<{ products: IProduct[] }> = ({ products }) => {
    const [visible, setVisible] = useState('1')
    return (
        <div className="pb-4">
            <div className="w-full mt-8">
                <img src={drinks} alt="drink_circles" />
                <div className="text-4xl font-medium uppercase festivo text-center mt-5 mb-5">
                    Poznaj wszystkie Przepisy
                    Schweppes
                </div>
            </div>
            <div className="px-3">
                {products.length && products.map(i => {
                    return <Item key={i.id} name={i.name} id={i.id} type={'i.type'} img={i.image} drinks={i.drinks} visible={visible === i.id} setVisible={setVisible} />
                })}
            </div>
        </div>
    )
}

export default Menu