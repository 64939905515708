import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import CarouselItem from "./carousel_item/carousel_item";

import ReactCarousel from "../react_carousel/react_carousel";

export interface IItem {
    id: string,
    title?: string,
    text?: string,
    thumbnail: string,
    bottle?: string,
    slug: string
}

interface IProps {
    items: IItem[]
}

const Carousel: React.FC<IProps> = ({ items }) => {
    return (
        <div className="mt-2 mb-1">
            <ReactCarousel>
                {items.map(i => <CarouselItem key={Math.random()} thumbnail={i.thumbnail} title={i.title} text={i.text} bottle={i.bottle} id={i.id} slug={i.slug} />)}
            </ReactCarousel>
        </div>
    )
}

export default Carousel