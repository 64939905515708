import { FC } from "react";
import { Link } from "react-router-dom";
import { IProduct } from "../../App";
import bottle from '../../assets/img/bottle_circles.png'

const Tastes: FC<{ products: IProduct[] }> = ({ products }) => {
    return (
        <div>
            <div className="w-full mt-8">
                <img src={bottle} alt="drink_circles" />
                <div className="text-4xl font-medium uppercase festivo text-center mt-3 mb-5">
                    Poznaj wszystkie smaki
                    Schweppes
                </div>
                <div className="mt-4 px-4 font-medium leading-snug text-lg">
                    Delikatnie wyważone kompozycje na bazie starannie dobranych składników. <br />
                    <strong>Wymieszaj z czym chcesz lub ugaś pragnienie natychmiast!</strong>
                </div>

                <div className="grid grid-cols-3 px-4 mt-8">
                    {products.map(p => {
                        return (
                            <Link to={`/smak/${p.id}`}>
                                <div className="mb-10">
                                    <div className="w-14 mx-auto">
                                        <img src={'http://qr.schweppes.testuj.org.pl' + p.image} alt="bottle" className="w-full" />
                                    </div>
                                    <p className="text-center uppercase font-medium festivo px-2 mt-4 leading-none">
                                        {p.name}
                                    </p>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Tastes