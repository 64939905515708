
import ph from '../../../assets/img/ph.jpg'
import { IItem } from '../carousel'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const CarouselItem: React.FC<IItem> = (props) => {
    return (
        <Link to={`/${props.slug}`}>
            <div className="w-full bg-black text-white min-h-full rounded-2xl overflow-hidden  flex flex-col justify-between">
                <div className="relative">
                    <img src={'https://api.schweppespolska.pl' + props.thumbnail} alt="" />
                    <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-t from-black to-transparent"></div>
                </div>
                <div className="flex justify-start items-end">
                    <div className="w-16 ml-4 h-full overflow-hidden flex-shrink-0">
                        <img src={props.bottle ? 'http://qr.schweppes.testuj.org.pl' + props.bottle : ph} alt="obrazek" className="w-4 -mb-16" />
                    </div>
                    <div className="text-left ml-4 pr-3">
                        <h1 className="text-4xl font-bold festivo">{props.title ? props.title : 'Ruby Russian'}</h1>
                        <P className="text-sm font-light mt-1 pb-16">{props.text ? props.text.replaceAll('<br />', '\n') : ''}</P>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CarouselItem

const P = styled.p`
    font-family: 'EB Garamond', serif;
`