import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import logo from '../../assets/img/logo_small.png'
import arrow from '../../assets/img/arrow_black.png'
import { useEffect, useRef, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
interface IProps {
    link?: string,
    toggleNav: (arg: boolean) => void,
    isOpen: boolean
}

const Nav: React.FC<IProps> = ({ toggleNav, isOpen }) => {
    const location = useLocation()
    const history = useHistory()
    const [hasBg, setHasBg] = useState(false)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener('scroll', () => {
            if (ref && ref.current) {
                if (ref.current.getBoundingClientRect().top < -10) setHasBg(true)
                else setHasBg(false)
            }
        })
        if (ref && ref.current) {
            if (ref.current.getBoundingClientRect().top < -10) setHasBg(true)
            else setHasBg(false)
        }
    }, [])

    return (
        <>
            <div ref={ref}></div>
            {location.pathname !== '/' && <div className={`w-screen flex items-center justify-between pt-3 pb-3 px-4 fixed top-0 left-0 z-20 ${hasBg && 'bg-yellow'} transform duration-200 ${isOpen ? '-translate-x-72' : 'translate-x-0'}`} >
                <div className="flex-1">
                    {location.pathname !== '/homepage' && <button onClick={() => (location.state as any)?.first ? history.push('/homepage') : history.goBack()} className="flex items-center text-4xl font-bold ">
                        <IoIosArrowBack className='' />
                        <span className="text-stripes-tight festivo">wstecz</span>
                    </button>}
                </div>

                <div className="flex-1">
                    {location.pathname !== '/homepage' && <img src={logo} className="w-12 mx-auto" alt="Logo" />}
                </div>
                <div className="flex-1 flex items-center justify-end">
                    <div className={`w-10 h-6 ${location.pathname === '/homepage' && 'mt-2.5 '} flex flex-col justify-between cursor-pointer ${isOpen ? 'opacity-0' : 'opacity-100'}`} onClick={() => toggleNav(!isOpen)}>
                        <span className='block w-full h-1 bg-black rounded-full'></span>
                        <span className='block w-full h-1 bg-black rounded-full'></span>
                        <span className='block w-full h-1 bg-black rounded-full'></span>
                    </div>
                </div>
            </div>}
        </>

    )
}

export default Nav