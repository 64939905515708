import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useHistory } from 'react-router'
import { useCookies } from "react-cookie"

import logo from '../../assets/img/logo_small.png'
import nie from '../../assets/img/NIE.png'
import circle_border from '../../assets/img/circle_border.png'
import circle_white from '../../assets/img/circle_white.png'
import circle_black from '../../assets/img/circle_black.png'

const AgeCheck: React.FC<{ path: string }> = ({ path }) => {
    const [blocked, setBlocked] = useState(false)

    const [cookies, setCookie] = useCookies()

    const history = useHistory()

    useEffect(() => {
        let id = history.location.search.split('=')[1]
        
        if (cookies.age === 'true') {
            if(path && path == '/') path = '/homepage';

            if (path) history.push(path, { first: true })
            else history.push('/homepage', { first: true })
        }
    }, [])

    const accept = () => {
        console.log(path)

        if(path && path == '/') path = '/homepage';

        setCookie('age', true, { expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 31 * 12 * 5) })
        if (path) history.push(path, { first: true })
        else history.push('/homepage', { first: true })
    }

    return (
        <div className="h-full w-full relative min-h-screen overflow-hidden">
            <div className="w-40 pt-8 mx-auto">
                <img src={logo} alt="logo" className="mx-auto" />
            </div>
            <div className="mx-auto flex flex-col items-center mt-16">
                <h1 className="inline-block text-7xl text-center font-bold uppercase festivo">Czy masz <br /> <span className="text-stripes festivo">18 lat?</span></h1>
                <div className="flex items-start mt-5 ">
                    <button className="text-5xl uppercase font-bold px-3 festivo " onClick={accept}>Tak</button>
                    <button className="px-3 text-stripes festivo  h-10 pb-1" onClick={() => setBlocked(true)}>
                        <img src={nie} alt="" className="h-full" />
                    </button>
                </div>
            </div>
            {/* kolka border */}
            <div className="w-7 h-7 border border-black absolute left-6 top-6 rounded-full"></div>
            <Dot left="40%" className="w-6 h-6 border border-black absolute bottom-20 rounded-full"></Dot>
            <DotBorder className="w-4 h-4 border border-black absolute right-5 rounded-full"></DotBorder>
            {/* czarne kolka */}
            <div className="w-4 h-4 bg-black absolute left-6 bottom-1/4 rounded-full"></div>
            <div className="w-4 h-4 bg-black absolute right-6 bottom-20 rounded-full"></div>
            <DotBlack className="w-3 h-3 bg-black absolute right-8 rounded-full"></DotBlack>
            <div className="w-2 h-2 bg-black absolute right-8 top-20 rounded-full"></div>
            {/* biale kolka */}
            <div className="w-12 h-12 bg-white absolute left-1/2 bottom-28 rounded-full"></div>
            {/* kolka podwojny border */}
            <img src={circle_border} alt="circle" className="w-16 absolute left-1/4 bottom-4" />
            <img src={circle_border} alt="circle" className="w-16 absolute -right-7 bottom-1/4" />
            {/* kolka paski */}
            <img src={circle_white} alt="circle" className="w-16 absolute -left-8 bottom-24" />
            <img src={circle_black} alt="circle" className="absolute right-4 top-24 w-9" />
        </div>
    )
}

export default AgeCheck

const Dot = styled.div<{ left: string }>`
    left: ${props => props.left};
`

const DotBlack = styled.div`
    bottom: 50%;
`

const DotBorder = styled.div`
    bottom: 49%;
`