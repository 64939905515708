import { Link } from "react-router-dom"
import { IDrink } from "../../../App"
import CarouselItem from "../../carousel/carousel_item/carousel_item"
import ReactCarousel from "../../react_carousel/react_carousel"

interface IProps {
    id: string,
    name: string,
    type: string,
    img: string,
    drinks: IDrink[],
    visible: boolean,
    setVisible: (arg: string) => void
}

const Item: React.FC<IProps> = (props) => {
    return (
        <div>
            {/* {props.visible && <ReactCarousel >
                {
                    props.drinks.map((d: IDrink) => {
                        return <CarouselItem key={d.id} thumbnail={d.image} title={d.name} text={d.description} bottle={props.img} id={props.id} />
                    })
                }
            </ReactCarousel>} */}
            <Link to={`/drinks/${props.id}`}>
                <div className="overflow-hidden " >
                    <div className="w-full pt-7 pb-6 rounded-2xl stripes-dark relative mt-4">
                        <img src={'https://api.schweppespolska.pl' + props.img} alt="bottle" className="absolute left-4 -top-1 w-10 " />
                        <h2 className="text-center text-22 font-bold ml-10 festivo">{props.name}</h2>
                    </div>
                </div>
            </Link>
        </div >
    )
}

export default Item