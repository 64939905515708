import { Link } from "react-router-dom"

interface IProps {
    text: string,
    stripes: 'stripes-light' | 'stripes-dark',
    link: string,
    novelty?: boolean
}

const Button: React.FC<IProps> = ({ text, stripes, link, novelty = false }) => {
    return (
        <Link to={link} className={`w-full pt-5 pb-5  mt-2 rounded-2xl text-center font-bold text-3xl block ${stripes} festivo relative`}>
            {text}
            {novelty && <div className="absolute right-4 -top-3 px-3 pt-0 pb-0.5 bg-btn_red text-base rounded-full text-white festivo font-normal">nowość</div>}
        </Link>
    )
}

export default Button