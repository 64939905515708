import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Route, Switch, useHistory } from "react-router";
import AgeCheck from "./components/age_check/age_check";
import DesktopView from "./components/desktop_view/desktop_view";
import Detail from "./components/detail/detail";
import Drink from "./components/drink/drink";
import Footer from "./components/footer/footer";
import Loader from "./components/loader/loader";
import Menu from "./components/menu/menu";
import Nav from "./components/nav/nav";
import Sidenav from "./components/sidenav/sidenav";
import Flavour from "./pages/flavour/flavour";
import Homepage from "./pages/homepage/homepage";
import Konkurs from "./pages/konkurs/konkurs";
import Tastes from "./pages/tastes/tastes";

export interface IDrink {
  id: string,
  description: string,
  shortdescription: string,
  preparation: string,
  info: string,
  ingredients: string,
  image: string,
  name: string,
  video: string,
  slug: string,
  video_poster: string
}

export interface IProduct {
  id: string,
  image: string,
  name: string,
  history: string,
  nutrients: { name: string, value: string }[],
  type: string,
  sort: number,
  drinks: IDrink[]
}

function App() {
  const router = useHistory()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState<IProduct[]>([])
  const [drinks, setDrinks] = useState<IDrink[]>([])

  const [navOpen, setNavOpen] = useState(false)

  const [cookies] = useCookies()

  const [path, setPath] = useState('')

  useEffect(() => {
    if (!cookies.age || cookies.age !== "true") {
      setPath(router.location.pathname)
      router.push('/');
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, status } = await axios.get('https://api.schweppespolska.pl/list-products')
        console.log(data)
        // console.log(data)
        // Object.entries(data).forEach((e: any) => console.log(e))
        let products: IProduct[] = []
        let drinks: IDrink[] = []
        Object.entries(data).forEach((o: any[]) => {
          products.push({ id: o[0], ...o[1] })
          drinks.push(...o[1].drinks)
        })
        products = products.sort((a, b) => a.sort - b.sort)
        setDrinks(drinks)
        setProducts(products)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <>
      <div className="block w-screen overflow-x-hidden lg:hidden">
        {!loading && <Nav toggleNav={setNavOpen} isOpen={navOpen} />}
        <div className={`min-h-screen w-full flex flex-col items-start justify-between transform duration-200 ${navOpen ? '-translate-x-72' : '-translate-x-0'}`}>
          {loading ? <Loader /> : <div className="w-full h-full pt-16">
            <Switch>
              <Route path="/" exact>
                <AgeCheck path={path} />
              </Route>
              <Route path="/homepage" exact>
                <Homepage />
              </Route>
              <Route path="/konkurs" exact>
                <Konkurs />
              </Route>
              <Route path="/smaki" exact>
                <Tastes products={products} />
              </Route>
              <Route path="/smak/:id" exact>
                <Flavour products={products} />
              </Route>
              <Route path="/drinks/:id" exact>
                <Drink products={products} />
              </Route>
              <Route path="/przepisy" exact>
                <Menu products={products} />
              </Route>
              <Route path="/:slug">
                <Detail drinks={drinks} products={products} />
              </Route>
            </Switch>
          </div>}
          <Footer />
        </div>
        <Sidenav navOpen={navOpen} toggleNav={setNavOpen} />
      </div>
      <div className="hidden lg:block">
        <DesktopView />
      </div>
    </>
  );
}

export default App;
