import { FC } from "react";
import logo from '../../assets/img/logo.png'
import qr from '../../assets/img/qr.png'
import circle_border from '../../assets/img/circle_border.png'
import circle_white from '../../assets/img/white_circle.png'
import circle_black from '../../assets/img/circle_black.png'

const DesktopView: FC = () => {
    return (
        <div className="min-h-screen px-28 relative py-7 overflow-hidden">
            <div className="w-52">
                <img src={logo} alt="Schweppes logo" />
            </div>
            <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <div className="mx-auto w-44">
                    <img src={qr} alt="" className="mx-auto" />
                </div>
                <div className="text-center text-2xl mt-6">
                    <p>
                        Witaj zbłąkany poszukiwaczu niezwykłych smaków!
                    </p>
                    <p>
                        Ciekawość potrafi zaprowadzić w niezwykłe miejsca, prawda? ;{'\)'}
                    </p>
                    <p>
                        To właśnie dzięki niej stworzyliśmy tyle oryginalnych smaków Schweppes, <br /> którymi chcemy się dzielić również z tobą!
                    </p>
                    <div className="mt-6 font-semibold">
                        <p>
                            Jeśli chcesz uruchomić tę stronę, weź do ręki smartfona i zeskanuj kod QR.
                        </p>
                    </div>
                    <div className="mt-6">
                        <p>
                            Zmiksujmy razem coś pysznego!
                        </p>
                    </div>
                </div>
            </div>

            <div className="absolute right-20 top-44">
                <img src={circle_border} alt="circle" />
            </div>
            <div className="absolute right-1/4 -bottom-6">
                <img src={circle_border} alt="circle" />
            </div>

            <div className="w-24 h-24 bg-white absolute -right-14 bottom-1/4 rounded-full" ></div>

            <div className="w-8 h-8 bg-black rounded-full absolute top-4 right-1/3"></div>
            <div className="w-8 h-8 bg-black rounded-full absolute bottom-24 right-72"></div>
            <div className="absolute left-1/2 bottom-20 xl:bottom-32 2xl:bottom-40 bg-red-400">
                <div className="relative">
                    <div className="w-8 h-8 bg-black rounded-full absolute top-0 left-0"></div>
                    <div className="absolute left-3 top-3 border border-black w-10 h-10 rounded-full"></div>
                </div>
            </div>
            <div className="w-5 h-5 bg-black rounded-full absolute bottom-32 left-64"></div>

            <div className="w-16 h-16 border border-black rounded-full absolute right-72 top-1/3"></div>

            <div className="absolute left-32 top-1/3">
                <img src={circle_white} alt="" />
            </div>
            <div className="absolute left-80 bottom-24">
                <img src={circle_black} alt="" />
            </div>
        </div>
    )
}

export default DesktopView