import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { IProduct } from "../../App";

import flavour_bg from '../../assets/img/flavour_bg.png'
import Button from "../../components/button/button";
import Loader from "../../components/loader/loader";

const Flavour: FC<{ products: IProduct[] }> = ({ products }) => {
    const [loading, setLoading] = useState(true)

    const params = useParams<{ id: string }>()
    const [product, setProduct] = useState<IProduct | undefined>(undefined)
    useEffect(() => {
        const prod = products.filter(p => p.id === params.id)
        if (!prod) return
        setProduct(prod[0])
        setLoading(false)
    }, [])
    return (
        <div>
            {loading && <Loader />}
            {product !== undefined && <div>
                <div className="mt-3 relative w-full">
                    <img src={flavour_bg} alt="" className="absolute left-0 top-1/2 transform -translate-y-1/2 w-full z-0" />
                    <img src={'https://api.schweppespolska.pl' + product.image} alt="bottle" className="w-16 mx-auto relative z-10" />
                </div>
                <div className="px-4">
                    <div className="mt-6 text-4xl uppercase font-medium festivo text-center leading-none letter-spacing">
                        {product.name.split(' ').map(e => {
                            if (e.toLowerCase() === 'schweppes') return <>
                                {e}
                                <br />
                            </>
                            else return `${e} `
                        })}
                    </div>
                    <div className="mt-1 text-center text-2xl font-medium festivo uppercase letter-spacing-xl">
                        {product.type}
                    </div>
                    <div className="mt-6">
                        <strong className="text-2xl">Historia:</strong>
                        <div className="text-lg font-medium">
                            {product.history}
                        </div>
                    </div>
                    <div className="mt-6">
                        <strong className="text-2xl">Informacje odżywcze:</strong>
                        <div className="text-lg font-medium grid grid-cols-2 leading-tight">
                            <div className="font-semibold">
                                Wartości
                            </div>
                            <div className="text-right font-semibold">
                                100ml
                            </div>
                            {product.nutrients.map(n => {
                                return (
                                    <>
                                        <div>
                                            {n.name}
                                        </div>
                                        <div className="text-right">
                                            {n.value}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="my-6">
                        <Button link={`/drinks/${product.id}`} stripes="stripes-dark" text="Odkryj przepisy" />
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Flavour