import Button from "../button/button"
import styled from 'styled-components'

import DetailCarouselItem from "./detail_carousel_item/detail_carousel_item"
import ReactCarousel from "../react_carousel/react_carousel"
import { IDrink, IProduct } from '../../App'
import { JSXElementConstructor, ReactElement, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router"

import play from '../../assets/img/play.png'

const Detail: React.FC<{ drinks: IDrink[], products: IProduct[] }> = ({ drinks, products }) => {
    const [loading, setLoading] = useState(true)
    const [drink, setDrink] = useState<IDrink>()
    const [playing, setPlaying] = useState(false)

    const history = useHistory()
    const { slug } = useParams<{ slug?: string }>()

    const ref = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        console.log(slug)
        const drink = drinks.filter(d => d.slug === slug)
        setDrink(drink[0])
        // let id = history.location.search.split('=')[1] || '1'
        // let drinkCopy = drinks.filter(d => d.id === id)
        // if (drinkCopy.length === 1) {
        //     console.log(drinkCopy)
        //     setDrink(drinkCopy[0])
        // }
        setLoading(false)
    }, [drinks, history.location.search])

    const formatSlides = (drink: IDrink) => {
        const slides: any = []
        console.log(drink)
        const keys: (keyof IDrink)[] = ['description', 'ingredients', 'preparation', 'info']
        Object.keys(drink).forEach((e) => {
            if (keys.includes(e as keyof IDrink) && drink[e as keyof IDrink].length > 0) {
                const title = e === 'description' ? 'Opis' : e === 'ingredients' ? 'Składniki' : e === 'preparation' ? 'Metoda' : 'Wskazówka'
                slides.push({
                    key: e,
                    value: drink[e as keyof IDrink],
                    section: title,
                    title: drink.name
                })
            }
        })

        console.log(slides)
        const slidesSorted = []
        for (let j = 0; j < slides.length; j++) {
            for (let i = 0; i < slides.length; i++) {
                if (slides[i].key === keys[j]) slidesSorted.push(slides[i])
            }
        }
        console.log(slidesSorted)

        const formattedSlides = slidesSorted.map((d: any) => {
            return <DetailCarouselItem title={d.title} text={d.value} section={d.section} />
        })

        return formattedSlides
    }

    return (
        <>
            {drink && <div className="px-3 pb-4">
                <Video className="w-full rounded-2xl mt-3 overflow-hidden" onClick={() => {
                    if (playing) {
                        setPlaying(false)
                        ref.current?.pause()
                    }
                }}>
                    <video src={'http://qr.schweppes.testuj.org.pl' + drink?.video} className="thumbnail" ref={ref} playsInline poster={'http://qr.schweppes.testuj.org.pl' + drink.video_poster}></video>
                    {/* <img src={video} alt="" className="thumbnail" /> */}
                    {!playing && <img src={play} className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14" alt="" onClick={() => { ref.current?.play(); setPlaying(true) }} />}
                </Video>
                <div className="my-4 rounded-2xl overflow-hidden">
                    <ReactCarousel>
                        {formatSlides(drink)}
                    </ReactCarousel>
                </div>
                <Button link="/konkurs" text="Weź udział w konkursie" stripes="stripes-light" novelty={true} />
                <Button link="/przepisy" text="Odkryj wszystkie przepisy" stripes="stripes-dark" />
            </div>}
        </>

    )
}

export default Detail

const Video = styled.div`
    width: 100%;
    padding-top: 62.5%;
    position: relative;
    cursor: pointer;

    & video.thumbnail{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        object-fit: cover;
    }
`