import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import arrow from '../../assets/img/arrow.png'

const ReactCarousel: React.FC<{
    children: (React.ReactChild[] & (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null)) | undefined
}> = ({ children }) => {
    return (
        <div className="mt-2 mb-1">
            <Carousel
                renderIndicator={(e, isSelected, index, label) => <div className={`w-4 h-4 rounded-full border border-yellow p-px mr-1 inline-block`}>
                    {isSelected && <div className="bg-yellow w-full h-full rounded-full"></div>}
                </div>}
                renderArrowPrev={(clickHandler: () => void, hasPrev: boolean, label: string) => <div className="h-full ml-2 w-5 flex items-center justify-center absolute left-0 top-0 z-50" onClick={clickHandler}>
                    <Reverse src={arrow} alt="arrow" className="w-2" />
                </div>}
                renderArrowNext={(clickHandler: () => void, hasNext: boolean, label: string) => <div className="h-full mr-2 w-5 flex items-center justify-center absolute right-0 top-0 z-50" onClick={clickHandler}>
                    <img src={arrow} alt="arrow" className="w-2" />
                </div>}
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                className="rounded-2xl  overflow-hidden"
            >
                {children}
            </Carousel>
        </div>
    )
}

export default ReactCarousel

const Reverse = styled.img`
    transform: scale(-1);
`